<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Jaime Javier Coronado Guerrero                              ###### -->
<!-- ###### @date: marzo 2024                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->

<template>
  <div>
    <v-dialog scrollable v-model="dialogHospitalario" persistent transition="dialog-bottom-transition" max-width="1000">
      <v-card> <!-- :style="{ height: this.activeForm == false ? '24rem' : '100%'}" -->
        <v-toolbar style="position: sticky; top: 0; z-index: 1; width: 100%;" color="primary" class="text-h6" dark>
          <div style="display: flex; justify-content: space-between; width: 100%;  ">
            <v-toolbar-title class="mt-2">Solicitud hospitalaria</v-toolbar-title>
            <v-btn icon dark @click="cerrarDialogHospitalario()">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <!-- <v-card elevation="5" rounded outlined class="mt-2 ms-6 me-5"> -->
        <v-card-text class="mt-6">
          <validation-observer v-slot="{ invalid }" ref="observerSolicitudHospitalario">
            <v-form :disabled="activeForm" class="mt-3" v-model="formValidarHospitalario">
              <div class="d-flex mt-3 mb-1">
                <validation-provider style="width: 25%;" v-slot="{ errors }" :rules="{ required: true }">
                  <v-autocomplete style="width: 95%;" :disabled="habilitarCamposAfiliados" v-model="formHospitalario.tipoDocumento"
                    :items="listaTipoDocumento" return-object :item-text="mostrarListaTipoDocumentos" dense outlined :error-messages="errors" label="Tipo documento *">
                  </v-autocomplete>
                </validation-provider>
                <validation-provider style="width: 25%;" v-slot="{ errors }" :rules="{ required: true }">
                  <v-text-field :disabled="(formHospitalario.tipoDocumento === '')" style="width: 95%;" v-model="formHospitalario.numeroDocumento" :counter="11"
                    :error-messages="errors" label="N° de documento *" dense outlined>
                  </v-text-field>
                </validation-provider>
                <v-menu ref="menuFechaRescate" v-model="menuFechaRescate" :close-on-content-click="false"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider style="width: 25%;" v-slot="{ errors }" :rules="{ required: true }">
                      <v-text-field style="width: 95%;" v-model="formHospitalario.fechaRescate" label="Fecha *"
                        :disabled="habilitarCamposSolicitud" :error-messages="errors" readonly v-bind="attrs" v-on="on" dense outlined>
                      </v-text-field>
                    </validation-provider>
                  </template>
                  <v-date-picker v-model="formHospitalario.fechaRescate" :active-picker.sync="activePicker"
                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    min="1950-01-01" locale="es-co" :first-day-of-week="1" @change="saveDateRescate">
                  </v-date-picker>
                </v-menu>
                <validation-provider style="width: 25%;" v-slot="{ errors }" :rules="{ required: true }">
                  <v-autocomplete style="width: 95%;" v-model="formHospitalario.ipsRescate" :items="ipsSeleccionada" dense
                    outlined :disabled="habilitarCamposSolicitud" :error-messages="errors" return-object :item-text="mostrarListaIps" label="IPS solicitante *">
                  </v-autocomplete>
                </validation-provider>
              </div>
              <div class="d-flex mt-1 mb-1">
                <validation-provider ref="primerNombre" style="width: 25%;" v-slot="{ errors }"
                  :rules="{ required: true, }">
                  <v-text-field style="width: 95%;" :disabled="habilitarCamposAfiliados" v-model="formHospitalario.primerNombre" :error-messages="errors"
                    class="me-3" label="Primer nombre *" dense outlined>
                  </v-text-field>
                </validation-provider>
                <div style="width: 25%;">
                  <v-text-field style="width: 95%;" :disabled="habilitarCamposAfiliados" v-model="formHospitalario.segundoNombre" label="Segundo nombre"
                    dense outlined>
                  </v-text-field>
                </div>
                <validation-provider ref="primerApellido" style="width: 25%;" v-slot="{ errors }"
                  :rules="{ required: true }">
                  <v-text-field style="width: 95%;" :disabled="habilitarCamposAfiliados" v-model="formHospitalario.primerApellido" class="me-6"
                    :error-messages="errors" label="Primer apellido *" dense outlined>
                  </v-text-field>
                </validation-provider>
                <div style="width: 25%;">
                  <v-text-field style="width: 95%;" :disabled="habilitarCamposAfiliados" v-model="formHospitalario.segundoApellido" label="Segundo apellido"
                    dense outlined>
                  </v-text-field>
                </div>
              </div>
              <div class="d-flex mt-1 mb-1">
                <div style="width: 25%;">
                  <v-text-field style="width: 95%;" :disabled="habilitarCamposAfiliados" v-model="formHospitalario.genero" dense
                    outlined label="Género *">
                  </v-text-field>
                </div>
                <div style="width: 25%;">
                  <v-autocomplete style="width: 95%;" :items="listaCohorte" disabled
                    v-model="formHospitalario.pacienteCohorte" dense outlined label="Paciente en cohorte">
                  </v-autocomplete>
                </div>
                <validation-provider v-if="!activeForm" style="width: 25%;" v-slot="{ errors }" :rules="{ required: true }">
                  <v-autocomplete @keyup="buscarGrupoCie($event.target.value)" style="width: 95%;" v-model="formHospitalario.diagnosticoRemision"
                    :items="listaGrupoDiagnostico" return-object :item-text="mostrarGrupoDiagnostico" dense outlined
                    :disabled="habilitarCamposSolicitud" :error-messages="errors" label="Diagnóstico remisión *">
                  </v-autocomplete>
                </validation-provider>
                <div v-if="activeForm" style="width: 25%;">
                  <v-text-field style="width: 95%;" v-model="detalleGrupoDiagnostico"
                     dense outlined label="Diagnóstico *">
                  </v-text-field>
                </div>
                <validation-provider style="width: 25%;" v-slot="{ errors }" :rules="{ required: true }">
                  <v-autocomplete style="width: 95%;" v-model="formHospitalario.especialidadSolicitada"
                    :items="listaEspecialidades" return-object :item-text="mostrarEspecialidades" dense outlined
                    :disabled="habilitarCamposSolicitud" :error-messages="errors" label="Especialidad solicitada *">
                  </v-autocomplete>
                </validation-provider>
              </div>
              <div v-if="!activeForm">
                <validation-provider class="d-flex" v-slot="{ errors }" :rules="{ required: true }">
                  <div style="display: flex; margin-right: 1rem; min-width: 9.4rem;" class="mt-2">
                    <strong class="me-1">Seleccione el anexo </strong>
                    <p style="color: red; display: inline;">*</p>
                  </div>

                  <v-file-input ref="refAnxeTres" v-model="formHospitalario.anexo" accept=".pdf" outlined dense
                    prepend-icon="" label="Seleccionar archivo" style="min-width: 30rem; max-width: 30rem;"
                    :error-messages="errors">
                    <template v-slot:selection="{ text }">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                  <div v-if="formHospitalario.anexo != null && formHospitalario.anexo != ''">
                    <v-btn small fab icon color="blue"
                      @click="visualizarDocumentoSubido(formHospitalario.anexo)"><v-icon>visibility</v-icon></v-btn>
                  </div>
                </validation-provider>
              </div>
              <div v-if="activeForm" style="margin-bottom: 1%; margin-top: 1%; color: rgb(46, 41, 41);">
                <p><strong>Documentos adjuntos a la solicitud</strong></p>
              </div>
              <div v-if="activeForm" style="display: flex; flex-wrap: wrap;">
                <div v-for="(item, i) in respuestaDetalle.anexo" :key="i">
                  <v-btn style="width: 10%;" :disabled="estadoSolicitud" :ref="item.idAnexo" small class="me-2 mb-2" :style="{
                    backgroundColor: colorBotonDocumento(item), color: 'white', fontSize: '0.8em',
                    width: '10rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'
                    }" :title="item.tipoAdjunto" @click="mostrarPdf(item, false)">{{ item.tipoAdjunto }}
                  </v-btn>
                </div>
              </div>
              <div v-if="activeForm" style="margin-bottom: 1%; margin-top: 1%; color: rgb(46, 41, 41);">
                <p><strong>Documentos adjuntos a la contrarreferencia</strong></p>
              </div>
              <div v-if="activeForm" style="display: flex; flex-wrap: wrap;">
                <div v-for="(item, i) in informacionContrarreferencia" :key="i">
                  <v-btn style="width: 10%;" :ref="item.idAnexo" small class="me-2 mb-2" :style="{
                    backgroundColor: colorBotonDocumento(item), color: 'white', fontSize: '0.8em',
                    width: '10rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'
                    }" :title="item.tipoAdjunto" @click="mostrarPdf(item, true)">{{ item.tipoAdjunto }}
                  </v-btn>
                </div>
              </div>
              <div>
                <div style="margin-bottom: 1%">
                  <p><strong>Justificación de la solicitud</strong></p>
                </div>
                <div style="width: 80%;">
                    <v-textarea :disabled="activeForm" v-model="formHospitalario.justificacion" class="mt-3" rows="3" outlined
                      label="Justificación">
                    </v-textarea>
                </div>
              </div>
              <div v-if="activeForm">
                <strong>
                  <p class="mb-0 mt-3">Historial de las observaciones</p>
                </strong>
                <div>
                  <v-data-table :headers="headerHospitalario" :items="listaObservacionesHospitalarias"
                    class="elevation mt-4" loading-text="Cargando...">
                    <template  v-slot:[`item.idAnexo`]="{ item }">
                      <v-tooltip v-if="item.idAnexo != null" bottom color="blue">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text icon color="blue" @click="mostrarPdf(item, true)" v-bind="attrs" v-on="on">
                            <v-icon>visibility</v-icon></v-btn>
                        </template>
                        <span>Ver</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </div>
              </div>
              <div class="d-flex justify-end">
                <v-btn class="me-3" text color="error" @click="cerrarDialogHospitalario()">Cerrar</v-btn>
                <v-btn v-if="!activeForm" class="me-2" depressed :disabled="invalid || respuestaValidarAfiliado.numeroDocumento == null " color="success"
                  @click="crearNuevaSolicitud()">Guardar </v-btn>
              </div>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
      <!-- </v-card> -->
    </v-dialog>
    <v-dialog scrollable v-model="dialogNuevoArchivo" max-width="850">
      <v-card>
        <v-toolbar style="position: sticky; top: 0; z-index: 1;" color="primary" class="text-h6" dark>
          <div style="display: flex; justify-content: space-between; width: 100%;  ">
            <v-toolbar-title class="mt-2">Formulario corrección</v-toolbar-title>
            <v-btn icon dark @click="cerrarDialogNuevoArchivo()">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <v-card-text>
          <validation-observer v-slot="{ invalid }" ref="observerNuevoArchivo">
            <v-form class="mt-3">
              <div>
                <validation-provider v-slot="{ errors }" :rules="{ required: true },">
                  <v-file-input v-model="fileNuevoArchivo" accept=".pdf" outlined dense prepend-icon=""
                    label="Seleccione el archivo de reemplazo" :error-messages="errors">
                  </v-file-input>
                </validation-provider>
              </div>
              <div style="display: flex; justify-content: end;">
                <v-btn :disabled="invalid" depressed class="me-2 mb-3" @click="guardarNuevoArchivo()" color="success">
                  Guardar </v-btn>
              </div>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>
    <LectorPdfRutas :dialogPdf="dialogPdf" :pdfUrl="pdfUrl" :idAnexo="idAnexo" :nombreArchivo="nombreArchivo"
      @cerrarDialogPdf="cerrarDialogPdf" @cambiarColorAprobado="cambiarColorAprobado"
      @cambiarColorRechazado="cambiarColorRechazado" :inhabilitarBotonesPdf="inhabilitarBotonesPdf" />
  </div>
</template>
<script>
/* import { required, regex, digits, min } from 'vee-validate/dist/rules'; */
import { required, regex, digits, min, integer } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';
import LectorPdfRutas from '@/components/LectorPdfRutas.vue';
import { mapState, mapMutations } from "vuex";
import moment from 'moment';
setInteractionMode('eager')
extend('required', {
  ...required,
  message: 'Este campo es requerido.',
});

extend('min', {
  ...min,
  message: 'Dirección erronea',
});

extend('digits', {
  ...digits,
  message: 'Número Incorrecto.',
});

extend('regex', {
  ...regex,
  message: 'Formato Incorrecto',
});

const debounce = function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};
export default {
  computed: {
    ...mapState(["auth", "enterprise"]),
    ipsSeleccionada() {
      const lista = this.userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_Interno')
        ? this.listaIps
        : this.listaIps2;
      return lista;
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    LectorPdfRutas
  },
  props: {
    dialogHospitalario: Boolean,
    listaObservacionesHospitalarias: Array,
    listaIps: Array,
    listaIps2: Array,
    listaGrupoDiagnostico: Array,
    listaEspecialidades: Array,
    listaTipoDocumento: Array,
    respuestaDetalle: Object,
    informacionContrarreferencia: Array,
    userRoles: Array,
    activeForm: Boolean,
    estadoSolicitud: Boolean,
    tipoSolicitudRequerida: integer,
    idRuta: integer,
    empresa: integer
  },
  data() {
    return {
      menuFechaRescate: false,
      formValidarHospitalario: true,
      activePicker: null,
      formData: new FormData(),
      dialogPdf: null,
      dialogNuevoArchivo: false,
      habilitarCamposAfiliados: false,
      habilitarCamposSolicitud: false,
      nombreArchivo: "",
      pdfUrl: "",
      idAnexo: null,
      fileNuevoArchivo: [],
      inhabilitarBotonesPdf: null,
      respuestaValidarAfiliado: [],
      detalleGrupoDiagnostico: "",
      codigoTipoDocumento: "",
      formHospitalario: {
        fechaRescate: "",
        ipsRescate: "",
        tipoDocumento: "",
        numeroDocumento: "",
        primerNombre: "",
        segundoNombre: "",
        primerApellido: "",
        segundoApellido: "",
        genero: "",
        diagnosticoRemision: "",
        especialidadSolicitada: "",
        pacienteCohorte: null,
        anexo: [],
        justificacion: ""
      },
      headerHospitalario: [
        { text: "PRESTADOR", value: "nombrePrestador", align: "ips", width: "15%", sortable: false },
        { text: "ESTADO", value: "estadoSeguimiento", align: "ips", width: "15%", sortable: false },
        { text: "CÓDIGO ACEPTACION", value: "codigoAceptacion", align: "center", width: "10%", sortable: false },
        { text: "TIPO AMBULANCIA", value: "ambulancia", align: "center", width: "15%", sortable: false },
        { text: "PRESTADOR AMBULANCIA", value: "prestadorAmbulancia", align: "center", width: "20%", sortable: false },
        { text: "OBSERVACIONES", value: "observacion", align: "center", width: "40%", sortable: false },
        { text: "USUARIO", value: "usuario", align: "center", width: "40%", sortable: false },
        { text: "FECHA OBSERVACION", value: "fechaObservacion", align: "center", width: "40%", sortable: false },
        { text: "ANEXO", value: "idAnexo", align: "center", width: "40%", sortable: false }
      ],
      listaConfirmacion: [
        { text: "SI", value: "SI" },
        { text: "NO", value: "NO" }
      ],

      listaCohorte: [
        { text: "SI", value: true },
        { text: "NO", value: false }],
    }
  },
  mounted() {
    this.$on('llenarCamposDetalles', respuestaDetalle => {
        this.formHospitalario.fechaRescate = moment( respuestaDetalle.fechaSolicitud).format('YYYY-MM-DD'),
        this.formHospitalario.numeroDocumento = respuestaDetalle.numeroDocumento,
        this.formHospitalario.ipsRescate = respuestaDetalle.nombrePrestador,
        this.detalleGrupoDiagnostico = respuestaDetalle.descripcionGrupo,
        this.formHospitalario.especialidadSolicitada = respuestaDetalle.descripcionEspecialidad
        this.formHospitalario.justificacion = respuestaDetalle.justificacion 
        this.codigoTipoDocumento = respuestaDetalle.codigoTipoDocumento;
    });
  },

  methods: {

    ...mapMutations([
      "showSuccess",
      "showError"
    ]),

    cerrarDialogNuevoArchivo() {
      this.dialogNuevoArchivo = false;
      this.fileNuevoArchivo = null;
      this.$refs.observerNuevoArchivo.reset();
    },


    guardarNuevoArchivo() {
      const formData = new FormData();
      formData.append('file', this.fileNuevoArchivo);
      this.$http.put("msa-localapp/api/v1/rutas/anexo/" + this.idAnexo, formData, 
      { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
        this.showSuccess("El archivo se ha reemplazado correctamente.")
        const nombreRef = this.$refs[this.nombreColorRefPdf];
        if (nombreRef) {
          nombreRef[0].$el.style.backgroundColor = 'orange'
          this.cerrarDialogNuevoArchivo()
        }
      }).catch(() => {
        this.showError("Error inesperado al reemplazar el archivo")
      })
    },

    crearNuevaSolicitud() {
      const parametros = {
        idAfiliado: this.respuestaValidarAfiliado.idAfiliado,
        idEmpresa: this.enterprise.code,
        fechaRescate: this.formHospitalario.fechaRescate,
        ipsRescate: this.formHospitalario.ipsRescate.id_prestador,
        numeroDocumento: this.formHospitalario.numeroDocumento,
        tipoDocumento: this.formHospitalario.tipoDocumento.tipoDocumento,
        primerNombre: this.formHospitalario.primerNombre,
        segundoNombre: this.formHospitalario.segundoNombre,
        primerApellido: this.formHospitalario.primerApellido,
        segundoApellido: this.formHospitalario.segundoApellido,
        // genero: this.formHospitalario.genero,
        pacienteCohorte: this.formHospitalario.pacienteCohorte,
        diagnosticoRemision: this.formHospitalario.diagnosticoRemision.igrupo_cie,
        especialidadSolicitada: this.formHospitalario.especialidadSolicitada.id_especialidad,
        tipoSolicitud: this.tipoSolicitudRequerida,
        idRuta: this.idRuta,
        justificacion: this.formHospitalario.justificacion
      }
      const obj = JSON.stringify(parametros);
      this.formData.append('objetoFinal', obj);
      this.formData.append('anexo', this.formHospitalario.anexo);
      this.$http.post("msa-localapp/api/v1/rutas/hospitalaria", this.formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
          this.showSuccess("Solictud creada correctamente");
          this.$emit('getListaSolicitudes');
          this.cerrarDialogHospitalario();
        }).catch((error) => {
          this.showError(error.response.data.message[0]);
        })
    },

    cerrarDialogHospitalario() {
      this.$emit('cerrarDialogHospitalario', { dialogHospitalario: false, tipoSolicitudRequerida: null });
      this.limpiarFormulario();
      this.formData = new FormData();
      this.$refs.observerSolicitudHospitalario.reset();
    },

    limpiarFormulario() {
      this.formHospitalario.fechaRescate = "";
      this.formHospitalario.ipsRescate = {};
      this.formHospitalario.tipoDocumento = "";
      this.formHospitalario.numeroDocumento = "";
      this.formHospitalario.primerNombre = "";
      this.formHospitalario.segundoNombre = "";
      this.formHospitalario.primerApellido = "";
      this.formHospitalario.segundoApellido = "";
      this.formHospitalario.genero = "";
      this.formHospitalario.diagnosticoRemision = "";
      this.formHospitalario.especialidadSolicitada = "";
      this.formHospitalario.pacienteCohorte = null;
      this.formHospitalario.ipsDestino = {};
      this.formHospitalario.anexo = [];
      this.formHospitalario.justificacion = '';
      this.respuestaValidarAfiliado = null;
      this.codigoTipoDocumento = "";
      this.habilitarCamposAfiliados = false;
    },

    saveDateRescate(date) {
      this.$refs.menuFechaRescate.save(date)
    },
    mostrarListaIps(item) {
      return item?.nombre_prestador || 'Sin nombre'
    },
    mostrarGrupoDiagnostico(item) {
      return item?.igrupo_cie + ' - ' + item?.idescripcion_grupo;
    },
    mostrarEspecialidades(item) {
      return item?.descripcion_especialidad
    },
    mostrarListaTipoDocumentos(item) {
      return item.descripcion;
    },

    validarAfiliado(item) {
      console.log(this.formHospitalario.tipoDocumento.tipoDocumento)
      console.log(item)
      if(this.formHospitalario.tipoDocumento.tipoDocumento != '' && item != '' && this.formHospitalario.tipoDocumento != null && item != null){
        let tipoDocumento;
        if(this.formHospitalario.tipoDocumento.tipoDocumento != null){
          console.log("aquiiiiiiiiiiiiiiiiiiiii")
          tipoDocumento = this.formHospitalario.tipoDocumento.tipoDocumento;
        }else{
          console.log("acaaaaaaaaaaaaaaaaaaaaaaaaa")
          tipoDocumento = this.codigoTipoDocumento;
        }
        return new Promise((resolve) => {
          let numeroDocumento = item != '' ? item : 'null'
          this.$http.get("msa-localapp/api/v1/afiliado/" + tipoDocumento + '/' + numeroDocumento).then((result) => {
            this.respuestaValidarAfiliado = result.data
            console.log(this.respuestaValidarAfiliado)
            resolve(true)
          }).catch(() => {
            resolve(false)
          })
        })
      }
    },
    
    buscarGrupoCie(param){
      this.$emit('delayGrupoDiagnostico', param);
    },

    async llenarCamposAfiliado() {
      await this.validarAfiliado(this.formHospitalario?.numeroDocumento)
      if (this.respuestaValidarAfiliado?.cohorte == true && this.formHospitalario.numeroDocumento != '') {
        this.habilitarCamposAfiliados = true;
        this.habilitarCamposSolicitud = false;
        this.formHospitalario.pacienteCohorte = this.respuestaValidarAfiliado?.cohorte;
        this.formHospitalario.tipoDocumento = this.respuestaValidarAfiliado?.descripcion;
        this.formHospitalario.primerNombre = this.respuestaValidarAfiliado?.primerNombre;
        this.formHospitalario.segundoNombre = this.respuestaValidarAfiliado?.segundoNombre;
        this.formHospitalario.primerApellido = this.respuestaValidarAfiliado?.primerApellido;
        this.formHospitalario.segundoApellido = this.respuestaValidarAfiliado?.segundoApellido;
        this.formHospitalario.numeroCelular = this.respuestaValidarAfiliado?.numeroCelular;
        this.formHospitalario.numeroTelefono = this.respuestaValidarAfiliado?.numeroTelefono;
        this.formHospitalario.genero = this.respuestaValidarAfiliado?.genero === 'M' ? 'Masculino' : this.respuestaValidarAfiliado?.genero === 'F' ? 'Femenino' : this.respuestaValidarAfiliado?.genero === 'I' ? 'Indeterminado' : this.respuestaValidarAfiliado?.genero;
      } else {
        this.habilitarCamposSolicitud = true;
        this.formHospitalario.pacienteCohorte = false;
        this.formHospitalario.primerNombre = "";
        this.formHospitalario.segundoNombre = "";
        this.formHospitalario.primerApellido = "";
        this.formHospitalario.segundoApellido = "";
        this.formHospitalario.numeroCelular = "";
        this.formHospitalario.numeroTelefono = "";
        this.formHospitalario.genero = "";
      }
    },

    obtenerPDF(item) {
      return new Promise((resolve, reject) => {
        this.$http.get("msa-localapp/api/v1/rutas/anexo/download/" + item.idAnexo, {
          responseType: 'blob',
        })
          .then((response) => {
            if (response.status === 200) {
              const pdfData = URL.createObjectURL(response.data);
              resolve(pdfData);
            } else {
              reject(new Error('Error al obtener el PDF'));
            }
          })
          .catch((error) => {
            console.log('Error al descargar el PDF:', error);
            reject(error);
          });
      });
    },

    async mostrarPdf(item, estado) {
      if(estado == true){
        this.inhabilitarBotonesPdf = true;
      }
      if(estado == false){
        this.inhabilitarBotonesPdf = false;
      }
      if (this.userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_ExternoSolicitante') && item.estado == 'Rechazado') {
        this.idAnexo = item.idAnexo;
        this.dialogNuevoArchivo = true;
        this.nombreColorRefPdf = item.idAnexo;
      } else {
        this.pdfUrl = await this.obtenerPDF(item);
        this.pageNum = 1;
        this.numPaginas = 0;
        this.nombreColorRefPdf = item.idAnexo;
        this.idAnexo = item.idAnexo;
        this.nombreArchivo = item.nombreArchivo;
        this.dialogPdf = true;
      }
    },

    visualizarDocumentoSubido(item) {
      this.pageNum = 1;
      this.numPaginas = 0;
      this.inhabilitarBotonesPdf = true;
      this.pdfUrl = URL.createObjectURL(item);
      this.dialogPdf = true;
    },
    cambiarColorAprobado() {
      const nombreRef = this.$refs[this.nombreColorRefPdf];
      nombreRef[0].$el.style.backgroundColor = 'rgba(76,175,80,255)'
      this.$emit('getListaSolicitudes');
    },
    cambiarColorRechazado() {
      const nombreRef = this.$refs[this.nombreColorRefPdf];
      nombreRef[0].$el.style.backgroundColor = 'rgba(255, 82, 82, 1)'
      this.$emit('getListaSolicitudes');
    },

    colorBotonDocumento(item) {
      if(item.estado == 'Pendiente'){
        return 'blue';
      }
      if(item.estado == 'Rechazado'){
        return 'rgba(255, 82, 82, 1)';
      }
      if(item.estado == 'Aceptado'){
        return 'rgba(76,175,80,255)'
      }
      if(item.estado == "Remplazado"){
        return 'orange'
      }
    },

    cerrarDialogPdf(objeto) {
      this.dialogPdf = objeto.dialogPdf;
      this.pdfUrl = "";
      this.pageNum = 1;
      this.numPaginas = 0;
      this.inhabilitarBotonesPdf = null;
    },
  },

  watch: {
    "formHospitalario.numeroDocumento": debounce(function () {
      this.llenarCamposAfiliado();
    }, 500),
    "respuestaValidarAfiliado": debounce(function () {
      if(this.respuestaValidarAfiliado?.cohorte == false || this.respuestaValidarAfiliado == null && this.tipoSolicitudRequerida > 0){
        this.showError("No se puede crear la solicitud ya que el afiliado no se encuentra en la ruta");
      }
    }, 500),
  },
}
</script>
<style scoped>
::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

::v-deep .elevation .v-data-footer {
  width: 100%;
}

::v-deep .elevation .v-data-footer__select .v-select {
  margin: 5px;
  margin-left: 10px;
}

::v-deep .v-input--is-disabled div div div input {
  color: rgb(56, 48, 48);
}

::v-deep .v-form div div div div div textarea {
  color: rgb(56, 48, 48);
}
</style>
<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Jaime Javier Coronado Guerrero                              ###### -->
<!-- ###### @date: marzo 2024                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->

<template>
  <div class="ms-4 me-4">
    <section class="d-flex mt-3 mb-2">
      <div style="width: 16%;">
        <v-text-field style="width: 95%;" v-model="filtroNumeroSolicitud" class="me-2" label="Numero solicitud" dense
          outlined></v-text-field>
      </div>
      <div style="width: 16%;">
        <v-autocomplete style="width: 95%;" v-model="estadoFiltro" class="me-2" label="Estado" :items="listaEstados" dense
          outlined></v-autocomplete>
      </div>
      <div style="width: 16%;">
        <v-autocomplete style="width: 95%;" v-model="filtroCups" class="me-2" label="Cups" :items="listaCups" :item-text="mostrarCodigoCups"
          dense outlined  return-object></v-autocomplete>
      </div>
      <div style="width: 16%;">
        <v-autocomplete style="width: 95%;" outlined v-model="filtroTipoDocumento" class="me-2" label="Tipo documento" :items="listaTipoDocumento" dense
          return-object :item-text="mostrarListaTipoDocumentos"></v-autocomplete>
      </div>
      <div style="width: 16%;">
        <v-text-field style="width: 95%;" outlined v-model="filtroNumeroDocumento" class="me-2" label="Numero documento" dense></v-text-field >
      </div>
      <v-menu ref="menuFechaFiltro" v-model="menuFechaFiltro" :close-on-content-click="false"
        transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <div style="width: 16%;">
            <v-text-field style="width: 95%;" v-model="fechaFiltro" label="Fecha"
              readonly v-bind="attrs" v-on="on" dense outlined>
            </v-text-field>
          </div>
        </template>
        <v-date-picker v-model="fechaFiltro" :active-picker.sync="activePicker"
          :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
          min="1950-01-01" locale="es-co" :first-day-of-week="1" @change="saveDateFiltro">
        </v-date-picker>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="limpiarFechaFiltro()">
          Borrar
        </v-btn>
      </v-menu>
      <v-tooltip style="width: 4%;" left color="success" class="me-2"
        v-if="(userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_Interno') || userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_ExternoSolicitante'))">
        <template v-slot:activator="{ on, attrs }">
          <v-btn @mousedown.prevent="dialogPresolicitud = true" small class="ms-2" fab color="success" v-bind="attrs"
            v-on="on">
            <v-icon> add </v-icon>
          </v-btn>
        </template>
        <span>Crear nueva solicitud</span>
      </v-tooltip>
    </section>
    <section class="d-flex mt-0 mb-1">
      <div style="width: 16%;">
        <v-autocomplete style="width: 95%;" outlined v-model="filtroIncluidoRuta" class="me-2" label="Nota tecnica" :items="listaFiltroConfirmacion" dense>
        </v-autocomplete>
      </div>
      <div style="width: 16%;">
        <v-autocomplete style="width: 95%;" outlined v-model="filtroCohorte" class="me-2" label="Cohorte" :items="listaFiltroConfirmacion" dense
          ></v-autocomplete>
      </div>
      <div style="width: 16%;">
        <v-autocomplete style="width: 95%;" outlined v-model="filtroAutoinmune" class="me-2" label="Autoinmune" :items="listaFiltroConfirmacion" dense
        ></v-autocomplete>
      </div>
      <div  style="width: 16%;" v-if="userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_Interno')">
        <v-autocomplete style="width: 95%;" v-model="filtroSolicitante" class="me-2" :item-text="mostrarListaIps" return-object :items="listaIps"
          label="Solicitante" dense outlined ></v-autocomplete>
      </div>
      <div style="width: 16%;" v-if="userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_Interno')">
        <v-autocomplete style="width: 95%;" v-model="filtroPrestador" class="me-2" :item-text="mostrarListaIps" return-object :items="listaIps"
          label="Prestador" dense outlined ></v-autocomplete>
      </div>
    </section>
    <div>
      <v-dialog scrollable v-model="dialogPresolicitud" max-width="500">
        <v-card>
          <v-form>
            <v-card>
              <v-toolbar class="text-h6" color="primary" dark>
                <span>Agregar solicitud</span>
                <v-btn icon dark @click="dialogPresolicitud = false">
                  <v-icon>close</v-icon>
                </v-btn></v-toolbar>
              <v-card-text>
                <div style="display:flex;" class="mb-2">
                  <v-select style="max-width: 100%; " v-model="ruta"  :items="listaRutas"
                  return-object :item-text="mostrarRutas" outlined dense
                    label="Ruta"></v-select>
                </div>
                <div style="display:flex;">
                  <v-select style="max-width: 100%;" v-model="tipoSolicitudRequerida" :items="listaTipoSolicitud" outlined dense
                    label="Tipo solicitud"></v-select>
                </div>
              </v-card-text>
            </v-card>
          </v-form>
        </v-card>
      </v-dialog>
    </div>
    <v-data-table :headers="headers" :items="listaSolicitudes" class="tableResults mt-0" height="59vh"
      loading-text="Cargando..." :footer-props="footerProps" :items-per-page="itemsPorPagina"
      :server-items-length="totalElementos" @update:items-per-page="(i) => itemsPorPagina = i"
      @update:page="(p) => pagina = p" fixed-header :loading="animacionTablaMaestra">
      <template v-slot:[`item.acciones`]="{ item }">
        <div style="display: flex;">
          <v-tooltip v-if="userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_Interno')" left color="error">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text icon color="error" :disabled="item.estadoSeguimiento=='Anulado'" v-bind="attrs" v-on="on" @click="anularSolicitud(item)">
                <v-icon class="ms-6" >deleted</v-icon>
              </v-btn>
            </template>
            <span>Anular</span>
          </v-tooltip>
          <v-tooltip left color="blue">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text icon color="blue" v-bind="attrs" v-on="on" @click="listarDetalle(item)">
                <v-icon>description</v-icon>
              </v-btn>
            </template>
            <span>Detalle</span>
          </v-tooltip>
          <v-tooltip left color="success"
            v-if="((userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_Interno') || (userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_ExternoPrestador') && item.tipoSolicitudRuta == 'Ambulatorio' )) || (item.tipoSolicitudRuta == 'Hospitalización-Rescate' && userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_ExternoSolicitante')))">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text icon color="success" :disabled="item.estadoSeguimiento=='Anulado'" v-bind="attrs" v-on="on" @click="abrirDialogObservacion(item)">
                <v-icon>post_add</v-icon>
              </v-btn>
            </template>
            <span>Agregar observación</span>
          </v-tooltip>
          <v-tooltip left color="success" v-if="((userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_ExternoPrestador')))">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text icon color="success" :disabled="item.estadoSeguimiento=='Anulado'" v-bind="attrs" v-on="on" @click="abrirDialogContrarreferencia(item)">
                <v-icon>note_add</v-icon>
              </v-btn>
            </template>
            <span>Contrarreferencia</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:[`item.prioritario`]="{ item }">
        <div class="d-flex align-center">
          {{ item.prioritario === true ? 'Si' : 'No' }}
        </div>
      </template>
      <template v-slot:[`item.cohorte`]="{ item }">
        <div class="d-flex align-center">
          {{ item.cohorte === true ? 'Si' : 'No' }}
        </div>
      </template>
      <template v-slot:[`item.incluidoRuta`]="{ item }">
        <div class="d-flex align-center">
          {{ item.incluidoRuta === true ? 'Si' : 'No' }}
        </div>
      </template>
      <template v-slot:[`item.autoinmune`]="{ item }">
        <div class="d-flex align-center">
          {{ item.autoinmune === true ? 'Si' : 'No' }}
        </div>
      </template>
      <template v-slot:item.estadoSeguimiento="{ item }">
      <td :class="cambiarColorEstado(item)">
        {{ item.estadoSeguimiento }}
      </td>
    </template>
    </v-data-table>
    <FormularioAmbulatorio ref="formularioAmbulatorio" @cerrarDialogSolicitud="cerrarDialogSolicitud"
      @getListaSolicitudes="getListaSolicitudes" @delayGrupoDiagnostico="delayGrupoDiagnostico"
       :dialogSolicitud="dialogSolicitud" :activeForm="activeForm"
      :activeEdit="activeEdit" :mensajePdf="mensajePdf" :respuestaDetalle="respuestaDetalle"
      :informacionContrarreferencia="informacionContrarreferencia" :listaIps="listaIps" :listaIps2="listaIps2"
      :listaGrupoDiagnostico="listaGrupoDiagnostico"
      :listaMunicipios="listaMunicipios" :listaCups="listaCups" :tipoSolicitudRequerida="tipoSolicitudRequerida"
      :listaEspecialidades="listaEspecialidades" :userRoles="userRoles" :listaObservaciones="listaObservaciones"
      :idRuta="ruta.idRuta" :listaTipoDocumento="listaTipoDocumento" :estadoSolicitud="estadoSolicitud" />
    <FormularioHospitalario ref="formularioHospitalario" :dialogHospitalario="dialogHospitalario"
      @delayGrupoDiagnostico="delayGrupoDiagnostico" :listaObservacionesHospitalarias="listaObservacionesHospitalarias" 
      :informacionContrarreferencia="informacionContrarreferencia" @cerrarDialogHospitalario="cerrarDialogHospitalario"
       @getListaSolicitudes="getListaSolicitudes" :listaIps="listaIps" :listaIps2="listaIps2"
      :listaGrupoDiagnostico="listaGrupoDiagnostico" :listaMunicipios="listaMunicipios"
      :listaEspecialidades="listaEspecialidades" :activeForm="activeForm" :userRoles="userRoles"
      :respuestaDetalle="respuestaDetalle" :tipoSolicitudRequerida="tipoSolicitudRequerida"
      :idRuta="ruta.idRuta" :listaTipoDocumento="listaTipoDocumento" :estadoSolicitud="estadoSolicitud" />
    <FormularioContrarreferencia :dialogContrarreferencia="dialogContrarreferencia"
      @cerrarDialogContrarreferencia="cerrarDialogContrarreferencia"
      :informacionFilaPrincipal="informacionFilaPrincipal" />
      <DialogAnulacionRutas ref="dialogAnulacionRutas" :dialogAnulacionRutas="dialogAnulacionRutas" :idSolicitudAnulada="idSolicitudAnulada"
      @cerrarDialogAnulacionRutas="cerrarDialogAnulacionRutas" @getListaSolicitudes="getListaSolicitudes"/>
    <!-- Modal para agregar afiliado -->
    <v-dialog scrollable v-model="dialogObservacion" max-width="1000">
      <v-card>
        <v-toolbar style="position: sticky; top: 0; z-index: 1;" color="primary" class="text-h6" dark>
          <div style="display: flex; justify-content: space-between; width: 100%;  ">
            <v-toolbar-title class="mt-2">{{ tituloObservacion }}</v-toolbar-title>
            <v-btn icon dark @click="cerrarDialogObservacion()">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <v-card-text>
          <validation-observer v-slot="{ invalid }" ref="observerAnotation">
            <v-form :disabled="activeForm" ref="formObservaciones" class="mt-3" v-model="formValidarObservacion">
              <div class="d-flex mt-3 mb-1 ms-2">
                <v-menu v-if="(idTipoSolicitud == 'Ambulatorio' && userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_Interno'))" ref="menuFechaGestion" v-model="menuFechaGestion" :close-on-content-click="false"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <div style="width: 25%;">
                      <v-text-field style="width: 95%;" v-model="formNewObservation.fechaGestion" label="Fecha gestión"
                        readonly v-bind="attrs" v-on="on" dense outlined>
                      </v-text-field>
                    </div>
                  </template>
                  <v-date-picker v-model="formNewObservation.fechaGestion" :active-picker.sync="activePicker"
                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    min="1950-01-01" locale="es-co" :first-day-of-week="1" @change="saveDateGestion"></v-date-picker>
                </v-menu>
                <v-menu ref="menuFechaCita" v-model="menuFechaCita" :close-on-content-click="false"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <div style="width: 25%;">
                      <v-text-field style="width: 95%;" v-model="formNewObservation.fechaCita" label="Fecha cita"
                        readonly v-bind="attrs" v-on="on" dense outlined>
                      </v-text-field>
                    </div>
                  </template>
                  <v-date-picker v-model="formNewObservation.fechaCita" :active-picker.sync="activePicker"
                    :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000 - 180 * 24 * 60 * 60 * 1000)).toISOString().substr(0, 10)" 
                    locale="es-co" :first-day-of-week="1" @change="saveDateCita">
                  </v-date-picker>
                </v-menu>
                <v-menu ref="menuHora" v-model="menuHora" :close-on-content-click="false" :nudge-right="40"
                  :return-value.sync="formNewObservation.horaCita" transition="scale-transition" offset-y
                  max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <div style="width: 25%;">
                      <v-text-field style="width: 95%;" v-model="formNewObservation.horaCita" outlined dense
                        label="Hora cita" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on">
                      </v-text-field>
                    </div>
                  </template>
                  <v-time-picker v-if="menuHora" v-model="formNewObservation.horaCita" full-width
                    @click:minute="$refs.menuHora.save(formNewObservation.horaCita)">
                  </v-time-picker>
                </v-menu>
                <div v-if="(idTipoSolicitud == 'Ambulatorio' && userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_Interno'))" style="width: 25%;">
                  <v-autocomplete style="width: 95%;" :items="listaIps" return-object :item-text="mostrarListaIps"
                    v-model="formNewObservation.prestador" label="Prestador" dense outlined>
                  </v-autocomplete>
                </div>
              </div>
              <div v-if="(idTipoSolicitud == 'Ambulatorio' && userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_Interno'))" class="d-flex mt-1 mb-1 ms-2" style="width: 75%;">
                <div style="width: 33%;">
                  <v-text-field type="number" style="width: 95%;" v-model="formNewObservation.radicado"
                    label="Radicado conexiones" dense outlined>
                  </v-text-field>
                </div>
                <v-menu ref="menuFechaCarga" v-model="formNewObservation.menuFechaCarga" :close-on-content-click="false"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <div style="width: 33%;">
                      <v-text-field style="width: 95%;" v-model="formNewObservation.fechaCarga"
                        label="Fecha carga masiva" readonly v-bind="attrs" v-on="on" dense outlined>
                      </v-text-field>
                    </div>
                  </template>
                  <v-date-picker v-model="formNewObservation.fechaCarga" :active-picker.sync="activePicker"
                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    min="1950-01-01" locale="es-co" :first-day-of-week="1" @change="saveDateCarga"></v-date-picker>
                </v-menu>
              </div>
              <div>
                <validation-provider style="width: 25%;" v-slot="{ errors }" :rules="{ required: true }">
                  <v-textarea style="max-width:96%" v-model="formNewObservation.observacion" class="mt-3 ms-2" rows="3"
                    outlined label="Observaciones" :error-messages="errors">
                  </v-textarea>
                </validation-provider>
              </div>
              <div class="d-flex justify-end">
                <v-btn small @click="abrirDialogDevolverSolicitud()" class="me-2" text color="error" style="padding: 17px;">
                  Devolver solicitud
                </v-btn>
                <v-btn class="me-2" depressed :disabled="invalid" @click="nuevaObservacionAmbulatorio()"
                  color="success">
                  Guardar
                </v-btn>
              </div>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDevolverSolicitud" max-width="500">
      <v-card>
        <v-toolbar style="position: sticky; top: 0; z-index: 1;" color="primary" class="text-h6" dark>
          <div style="display: flex; justify-content: space-between; width: 100%;  ">
            <v-toolbar-title class="mt-2">Devolución de la solicitud</v-toolbar-title>
            <v-btn icon dark @click="dialogDevolverSolicitud = false">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <v-card-text>
          <!-- Campo de lista de motivos -->
          <v-select
            v-model="motivoSeleccionado"
            :items="motivos"
            label="Motivo de la devolución"
            outlined
            dense
            style="margin-top: 27px" >
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="dialogDevolverSolicitud = false">Cerrar</v-btn>
            <v-btn class="me-2" depressed :disabled="invalid" @click="nuevaObservacionAmbulatorio(true)" color="success">
              Devolver solicitud
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="dialogObservacionHospi" max-width="1000">
      <v-card>
        <v-toolbar style="position: sticky; top: 0; z-index: 1;" color="primary" class="text-h6" dark>
          <div style="display: flex; justify-content: space-between; width: 100%;  ">
            <v-toolbar-title class="mt-2">{{ tituloObservacion }}</v-toolbar-title>
            <v-btn icon dark @click="cerrarDialogObservacionHospi()">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <v-card-text>
          <validation-observer v-slot="{ invalid }" ref="observerAnotationHospi">
            <v-form :disabled="activeForm" ref="formObservaciones" class="mt-3" v-model="formObserverHospi">
              <div v-if="(!activeForm && userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_Interno'))" class="mt-3 mb-1 ms-2"
                style="display: flex; justify-content: space-between;">
                <div style="width: 25%;">
                  <v-autocomplete style="width: 95%;" class="me-5" v-model="formNewObservationHospi.prestador"
                    :items="listaIps" dense outlined return-object :item-text="mostrarListaIps" label="Prestador">
                  </v-autocomplete>
                </div>
                <div style="width: 25%;">
                  <v-text-field style="width: 95%;" class="me-5" v-model="formNewObservationHospi.codigoAceptacion"
                    label="Codigo aceptación" dense outlined>
                  </v-text-field>
                </div>
                <div style="width: 25%;">
                  <v-autocomplete style="width: 95%;" class="me-5" v-model="formNewObservationHospi.ambulancia"
                    :items="listaAmbulancias" dense outlined label="Tipo ambulancia">
                  </v-autocomplete>
                </div>
                <validation-provider v-if="formNewObservationHospi.ambulancia === 'Otra'" style="width: 25%;" v-slot="{ errors }" :rules="{ required: true }">
                  <v-text-field style="width: 95%;" class="me-5" v-model="formNewObservationHospi.ambulanciaOpcional"
                    label="Ingrese el tipo de ambulancia *" dense outlined :error-messages="errors">
                  </v-text-field>
                </validation-provider>
                <div style="width: 25%;">
                  <v-autocomplete style="width: 95%;" v-model="formNewObservationHospi.PrestadorAmbulancia"
                    :items="listaPrestadorAmbulancias" dense outlined label="Prestador ambulancia">
                  </v-autocomplete>
                </div>
              </div>
              <div  v-if="userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_Interno')" class="ms-2">
                <div style="display: flex; margin-right: 1rem; min-width: 9.4rem;" class="mt-2">
                  <strong class="me-1 mb-2">Seleccione el anexo </strong>
                </div>
                <section class="d-flex">
                  <v-file-input ref="refAnxeTres" v-model="formNewObservationHospi.anexo" accept=".pdf" outlined dense
                    prepend-icon="" label="Seleccionar archivo" style="min-width: 30rem; max-width: 30rem;">
                    <template v-slot:selection="{ text }">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                  <div v-if="formNewObservationHospi.anexo != null && formNewObservationHospi.anexo != ''">
                    <v-btn small fab icon color="blue"
                      @click="visualizarDocumentoSubido(formNewObservationHospi.anexo)"><v-icon>visibility</v-icon></v-btn>
                  </div>
                </section>
              </div>
              <div v-if="!activeForm">
                <validation-provider style="width: 25%;" v-slot="{ errors }" :rules="{ required: true }">
                  <v-textarea style="max-width:98%" v-model="formNewObservationHospi.observacion" class="mt-1 ms-2"
                    rows="3" :error-messages="errors" outlined label="Observaciones">
                  </v-textarea>
                </validation-provider>
              </div>
              <div class="d-flex justify-end">
                <v-btn small @click="abrirDialogDevolverSolicitudHospi()" class="me-2" text color="error" style="padding: 17px;" v-if="(userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_Interno') || userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_ExternoPrestador'))">
                  Devolver solicitud
                </v-btn>
                <v-btn v-if="!activeForm" class="me-2" depressed :disabled="invalid"
                  @click="nuevaObservacionHospitalario()" color="success">
                  Guardar </v-btn>
              </div>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDevolverSolicitudHospi" max-width="500">
      <v-card>
        <v-toolbar style="position: sticky; top: 0; z-index: 1;" color="primary" class="text-h6" dark>
          <div style="display: flex; justify-content: space-between; width: 100%;  ">
            <v-toolbar-title class="mt-2">Devolución de la solicitud</v-toolbar-title>
            <v-btn icon dark @click="dialogDevolverSolicitudHospi = false">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-select
            v-model="motivoSeleccionado"
            :items="motivos"
            label="Motivo de la devolución"
            outlined
            dense
           ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="dialogDevolverSolicitudHospi = false">Cerrar</v-btn>
          <v-btn class="me-2" depressed :disabled="invalid" color="success" @click="nuevaObservacionHospitalario(true)">Devolver Solicitud</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <LectorPdfRutas :dialogPdf="dialogPdf" :pdfUrl="pdfUrl" :inhabilitarBotonesPdf="inhabilitarBotonesPdf"
      @cerrarDialogPdf="cerrarDialogPdf"/>
  </div>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>

import { required, regex, digits, min } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';
import { mapMutations, mapState } from "vuex";
import LectorPdfRutas from '@/components/LectorPdfRutas.vue';
import FormularioAmbulatorio from '@/components/FormularioAmbulatorio.vue';
import FormularioHospitalario from '@/components/FormularioHospitalario.vue';
import FormularioContrarreferencia from '@/components/FormularioContrarreferencia.vue';
import DialogAnulacionRutas from '@/components/DialogAnulacionRutas.vue';
import moment from 'moment';
setInteractionMode('eager')
extend('required', {
  ...required,
  message: 'Este campo es requerido.',
});

extend('min', {
  ...min,
  message: 'Dirección erronea',
});

extend('digits', {
  ...digits,
  message: 'Número Incorrecto.',
});

extend('regex', {
  ...regex,
  message: 'Formato Incorrecto',
});

const debounce = function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    LectorPdfRutas,
    FormularioAmbulatorio,
    FormularioHospitalario,
    FormularioContrarreferencia,
    DialogAnulacionRutas
  },

  computed: {
    ...mapState(["auth", "enterprise"]),
  },
  created() {
    this.userRoles = this.auth.roles;
    this.getListaSolicitudes();
    this.getListaPrestadores();
    this.getPrestadorPorUsuario();
    this.getListaCodigosCups();
    this.getListaRutas();
    this.getTipoDocumentos();
  },

  data() {
    return {
      filtroSolicitante: '',
      filtroPrestador: '',
      fechaFiltroText: '',
      fechaFiltro: '',
      filtroCups: '',
      filtroNumeroSolicitud: null,
      filtroTipoDocumento: {},
      filtroNumeroDocumento: null,
      filtroIncluidoRuta: null,
      filtroCohorte: null,
      filtroAutoinmune: null,
      userRoles: {},
      estadoFiltro: null,
      idTipoSolicitud: null,
      menuFechaGestion: false,
      menuFechaCita: false,
      menuFechaFiltro: false,
      menuHora: false,
      activePicker: null,
      pdfUrl: "",
      idSolicitudAnulada: null,
      dialogAnulacionRutas: false,
      dialogPdf: false,
      inhabilitarBotonesPdf: false,
      dialogPresolicitud: false,
      dialogSolicitud: false,
      dialogHospitalario: false,
      dialogContrarreferencia: false,
      dialogObservacion: false,
      dialogObservacionHospi: false,
      formValidarObservacion: true,
      formObserverHospi: true,
      activeForm: false,
      activeEdit: false,
      tipoSolicitudRequerida: null,
      ruta: "",
      idSolicitud: null,
      tipoProceso: "",
      mensajePdf: "",
      nombreColorRef: "",
      arrayModificado: [],
      listaObservaciones: [],
      respuestaValidarPrestador: null,
      tituloObservacion: "",
      informacionFilaPrincipal: {},
      listaObservacionesHospitalarias: [],
      itemsPorPagina: 10,
      pagina: 1,
      totalElementos: 0,
      animacionTablaMaestra: false,
      estadoSolicitud: null,
      formNewObservation: {
        fechaGestion: "",
        fechaCita: "",
        horaCita: "",
        radicado: "",
        prestador: {},
        fechaCarga: "",
        observacion: "",
      },
      formNewObservationHospi: {
        prestador: {},
        codigoAceptacion: "",
        ambulancia: "",
        ambulanciaOpcional: "",
        PrestadorAmbulancia: "",
        observacion: "",
        anexo: [],
      },
      headers: [
        { text: "NÚMERO SOLICITUD", value: "idSolicitud", align: "center", sortable: false },
        { text: "TIPO SOLICITUD", value: "tipoSolicitudRuta", align: "center", sortable: false },
        { text: "TIPO DOCUMENTO", value: "tipoDocumento", align: "center", sortable: false },
        { text: "NUMERO DOCUMENTO", value: "numeroDocumento", align: "center", sortable: false },
        { text: "NOMBRE AFILIADO", value: "nombreCompleto", align: "center", sortable: false, width:"20rem" },
        { text: "CUPS", value: "descripcionCups", align: "center", width: "15rem", sortable: false },
        { text: "ESTADO", value: "estadoSeguimiento", align: "center", sortable: false },
        { text: "NOTA TECNICA", value: "incluidoRuta", align: "center", sortable: false },
        { text: "AUTOINMUNE", value: "autoinmune", align: "center", sortable: false },
        { text: "SUBREGION", value: "subRegion", align: "center", sortable: false },
        { text: "DIAGNÓSTICO", value: "descripcionCIE", align: "center", sortable: false },
        { text: "CELULAR", value: "numeroCelular", align: "center", sortable: false },
        { text: "COHORTE", value: "cohorte", align: "center", sortable: false },
        { text: "PRIORITARIA", value: "prioritario", align: "center", sortable: false },
        { text: "SOLICITANTE", value: "nombrePrestador", align: "center", sortable: false },
        { text: "PRESTADOR", value: "nombrePrestadorAsignado", align: "center", sortable: false },
        { text: "FECHA SOLICITUD", value: "createDate", sortable: false, align: "center", width: "10rem"},
        { text: "ACCIONES", value: "acciones", align: "center", sortable: false},
      ],
      listaTipoSolicitud: [
        { text: "Ambulatorio", value: 0 },
        { text: "Hospitalario - Remision", value: 1 },
        { text: "Hospitalario - Rescate", value: 2 },
        { text: "Hospitalario - Domiciliario", value: 3 }
      ],
      listaAmbulancias: [
        { text: "Basica", value: "basica" },
        { text: "Medicalizada", value: "medicalizada" },
        { text: "Aerea", value: "Aerea" },
        { text: "Otra", value: "Otra" },
      ],
      listaPrestadorAmbulancias: [
        { text: "Alpha ambulancias", value: "alpha" },
        { text: "Ambuvida", value: "ambuvida" },
        { text: "EMI", value: "emi" },
        { text: "Aprehsi", value: "O" }
      ],
      listaFiltroConfirmacion:[
        {text: "SI", value: true},
        {text: "NO", value: false}
      ],
      listaEstados: [
        { text: "Creado", value: 0 },
        { text: "Aceptado", value: 1 },
        { text: "Rechazado", value: 2 },
        { text: "Asignado a IPS", value: 3 },
        { text: "Gestionado", value: 4 },
        { text: "Anulado", value: 5 },
        { text: "Atendido", value: 6 },
        { text: "Finalizado", value: 7 },
        { text: "Actualizado", value: 8 },
        { text: "Devuelto", value: 9 }
      ],
      footerProps: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Items por página:',
        'show-current-page': true,
        'show-first-last-page': true,
      },
      listaSolicitudes: [],
      listaTipoDocumento:[],
      respuestaDetalle: {},
      informacionContrarreferencia: [],
      listaIps: [],
      listaIps2: [],
      listaMunicipios: [],
      listaEspecialidades: [],
      listaPdf: [],
      listaGrupoDiagnostico: [],
      listaCups: [],
      listaRutas:[],

      dialogDevolverSolicitud: false,
      dialogDevolverSolicitudHospi: false,
      motivoSeleccionado: null,
      motivos: [
        'No comunicación paciente',
        'Prestación ya realizada',
        'No cumple con los requerimientos',
        'No acepta',
        'Desafiliado',
        'Fallecido'
      ],
      invalid: false
    }
  },



  methods: {
    ...mapMutations([
      "showSuccess",
      "showError"
    ]),

    cambiarColorEstado(item){
      if(item.estadoSeguimiento === 'Rechazado' && this.userRoles.includes('MallaValidadora_SolicitudServicio_FormularioSolicitud_ExternoSolicitante')){
        return 'color-red';
      }
    },

    mostrarListaTipoDocumentos(item) {
      return item?.descripcion
    },

    mostrarListaIps(item) {
      return item?.nombre_prestador
    },

    mostrarRutas(item) {
      return item?.nombreRuta
    },

    mostrarCodigoCups(item) {
      return item?.cups + " - " + item.descripcion_cups
    },

    getListaSolicitudes() {
      console.log(this.filtroPrestador?.id_prestador)
      this.$http.get("msa-localapp/api/GetSolicitudes", {
        params: {
          idSolicitud: this.filtroNumeroSolicitud,
          cup: this.filtroCups?.cups,
          idSolicitante: this.filtroSolicitante?.id_prestador,
          idPrestador: this.filtroPrestador?.id_prestador,
          estado: this.estadoFiltro,
          tipoDocumento: this.filtroTipoDocumento?.tipoDocumento,
          numeroDocumento: this.filtroNumeroDocumento,
          incluidoRuta: this.filtroIncluidoRuta,
          cohorte: this.filtroCohorte,
          autoinmune: this.filtroAutoinmune,
          createDate: this.fechaFiltro,
          page: this.pagina - 1,
          size: this.itemsPorPagina,
          sort: 'idSolicitud,desc'
        }
      }).then(({ data }) => {
        this.totalElementos = data.totalElements;
        this.listaSolicitudes = data.content;
        this.animacionTablaMaestra = false;
        console.log(this.listaSolicitudes)
      }).catch((err) => {
        console.log(err);
        this.animacionTablaMaestra = false;
      })
    },
    getListaPrestadores() {
      this.$http.get("msa-localapp/api/GetListaIPSPrestadores").then((result) => {
        this.listaIps = result.data;
      })
    },
    getListaMunicipios() {
      this.$http.get("msa-localapp/api/GetListaMpiosColombia").then((result) => {
        this.listaMunicipios = result.data
      })
    },

    getListaRutas(){
      this.$http.get("msa-localapp/api/v1/ruta//informacion-rutas").then((result) => {
        this.listaRutas = result.data
      })
    },

    delayGrupoDiagnostico: debounce(function(param) {
      this.getListaGrupoDiagnostico(param)
    }, 500),

    getListaGrupoDiagnostico(param) {
      this.$http.get("msa-localapp/api/GetListaGruposDiagnostico", {
        params:{
          diagnostico: param
        }
      }).then((result) => {
        this.listaGrupoDiagnostico = result.data
      })
    },
    
    getListaEspecialidaes() {
      this.$http.get("msa-localapp/api/GetListaEspecialidades").then((result) => {
        this.listaEspecialidades = result.data
      })
    },
    getListaCodigosCups() {
      this.$http.get("msa-localapp/api/GetListaCups").then((result) => {
        result.data.forEach(cup => cup.estado = 0);
        this.listaCups = result.data
      })
    },
    getTipoDocumentos() {
      this.$http.get("msa-localapp/api/v1/tipo-documento").then((result) => {
        this.listaTipoDocumento = result.data;
      })
    },

    abrirDialogSolicitud() {
      if (this.tipoSolicitudRequerida == 0) {
        this.activeGuardar = true;
        this.activeForm = false;
        this.dialogSolicitud = true;
        this.visiblePdf = true;
        this.mensajePdf = "Cargar documentos en formato .pdf";
        this.getTipoDocumentos();
        this.getListaPrestadores();
        this.getPrestadorPorUsuario();
        this.getListaMunicipios()
        this.getListaCodigosCups();
        this.getListaEspecialidaes();
      }
      if (this.tipoSolicitudRequerida > 0) {
        this.getTipoDocumentos();
        this.getListaPrestadores();
        this.getPrestadorPorUsuario();
        this.getListaEspecialidaes();
        this.dialogHospitalario = true;
        this.activeForm = false;
      }
    },

    abrirDialogContrarreferencia(item) {
      this.informacionFilaPrincipal = item;
      this.dialogContrarreferencia = true;
    },
    cerrarDialogContrarreferencia() {
      this.dialogContrarreferencia = false;
    },

    abrirDialogObservacion(item) {
      this.idSolicitud = item.idSolicitud;
      this.activeForm = false;
      this.idTipoSolicitud = item.tipoSolicitudRuta
      if (item.tipoSolicitudRuta == 'Ambulatorio') {
        this.getListaPrestadores();
        this.getPrestadorPorUsuario();
        this.dialogObservacion = true;
        this.tituloObservacion = "Observación para la solicitud ambulatoria número: " + item.idSolicitud;
      }
      if (item.tipoSolicitudRuta !=  'Ambulatorio') {
        this.getListaPrestadores();
        this.getPrestadorPorUsuario();
        this.dialogObservacionHospi = true;
        this.tituloObservacion = "Observación para la solicitud hospitalaria número: " + item.idSolicitud;
      }
    },

    abrirDialogDevolverSolicitud() {
      this.dialogDevolverSolicitud = true;
    },
    abrirDialogDevolverSolicitudHospi() {
      this.dialogDevolverSolicitudHospi = true;
    },

    validarPrestador(){
      return new Promise((resolve) => {
        this.$http.get("msa-localapp/api/v1/prestador/last-prestador-by-solicitud/" + this.idSolicitud).then((result) => {
          this.respuestaValidarPrestador = result.data
          resolve(true);
        }).catch(() => {

        })
      })
    },

    async nuevaObservacionAmbulatorio(esDevolucion = false) {
      // Si no es devolución, validamos las fechas y el prestador como en el método original
      if (!esDevolucion) {
        if ((this.formNewObservation.fechaCita == '' && this.formNewObservation.horaCita != '') ||
          (this.formNewObservation.fechaCita != '' && this.formNewObservation.horaCita == '')) {
          this.showError("Debe agregar fecha y hora de la cita");
          return;
        }
        if (this.formNewObservation.fechaCita != '' && this.formNewObservation.horaCita != '') {
          await this.validarPrestador();
        } else {
          this.respuestaValidarPrestador = true;
        }
      }

      // Definir la observación dependiendo si es una devolución o una observación normal
      let observacion;
      if (esDevolucion) {
        // Si es devolución, usamos el motivo seleccionado
        observacion = this.motivoSeleccionado;
        if (!observacion) {
          this.showError("Debe seleccionar un motivo de devolución");
          return;
        }
      } else {
        // Si no es devolución, usamos la observación escrita por el usuario
        observacion = this.formNewObservation.observacion;
        if (!observacion) {
          this.showError("Debe ingresar una observación");
          return;
        }
      }

      if (this.respuestaValidarPrestador != null || this.formNewObservation.prestador.id_prestador != null){
     
      // Enviar el payload a la API
      this.$http.post("msa-localapp/api/v1/rutas/crear-observacion-ambulatoria", {
        idSolicitud: this.idSolicitud,
        fechaGestion: this.formNewObservation.fechaGestion == '' ? null : this.formNewObservation.fechaGestion,
        fechaCita: this.formNewObservation.fechaCita == '' ? null : moment(this.formNewObservation.fechaCita).format('YYYY-MM-DD'),
        horaCita: this.formNewObservation.horaCita == '' ? null : this.formNewObservation.horaCita + ':00',
        idPrestador: this.formNewObservation.prestador.id_prestador,
        radicados: this.formNewObservation.radicado == '' ? null : this.formNewObservation.radicado,
        fechaCarga: this.formNewObservation.fechaCarga == '' ? null : this.formNewObservation.fechaCarga,
        observacion: observacion,  // El motivo de devolución o la observación regular
        estadoDevolucion: esDevolucion  // true si es devolución, false si es observación regular
      }).then(() => {
        if (esDevolucion) {
          this.dialogDevolverSolicitud = false;
          this.showSuccess("La solicitud ha sido devuelta correctamente");
        } else {
          this.dialogObservacion = false;
          this.showSuccess("Se ha creado la observación correctamente");
        }
        this.limpiarObservacionAmbulatorio();
      }).catch(err => console.log(err));

      } else {
        this.showError('La solicitud no tiene prestador, debe agregar uno para seleccionar fecha y hora de la cita')
      }

    },

    async nuevaObservacionHospitalario(esDevolucion = false) {
      let formData = new FormData();
      let ambulancia = null;

      // Determinar valor de ambulancia
      if (this.formNewObservationHospi.ambulancia == 'Otra') {
        ambulancia = this.formNewObservationHospi.ambulanciaOpcional;
      } else {
        ambulancia = this.formNewObservationHospi.ambulancia;
      }

      if (this.formNewObservationHospi.ambulancia == '') {
        ambulancia = null;
      }

      // Definir la observación dependiendo si es una devolución o una observación normal
      let observacion;
      if (esDevolucion) {
        // Si es devolución, usamos el motivo seleccionado
        observacion = this.motivoSeleccionado;
        if (!observacion) {
          this.showError("Debe seleccionar un motivo de devolución");
          return;
        }
      } else {
        // Si no es devolución, usamos la observación escrita por el usuario
        observacion = this.formNewObservationHospi.observacion;
        if (!observacion) {
          this.showError("Debe ingresar una observación");
          return;
        }
      }

      // Crear el objeto de parámetros
      let parametros = {
        idSolicitud: this.idSolicitud,
        idPrestador: this.formNewObservationHospi.prestador?.id_prestador,
        codigoAceptacion: this.formNewObservationHospi.codigoAceptacion == '' ? null : this.formNewObservationHospi.codigoAceptacion,
        ambulancia: ambulancia,
        prestadorAmbulancia: this.formNewObservationHospi.PrestadorAmbulancia == '' ? null : this.formNewObservationHospi.PrestadorAmbulancia,
        observacion: observacion,  // El motivo de devolución o la observación normal
        fechaSeguimiento: new Date(),
        estadoDevolucion: esDevolucion  // true si es devolución, false si es observación normal
      };

      // Convertir el objeto a JSON y añadirlo al FormData
      const obj = JSON.stringify(parametros);
      formData.append("body", obj);
      formData.append('file', this.formNewObservationHospi.anexo);

      // Validar prestador si hay código de aceptación
      if (this.formNewObservationHospi.codigoAceptacion != '') {
        await this.validarPrestador();
      } else {
        this.respuestaValidarPrestador = true;
      }

      // Si se valida el prestador, enviar el request
      if (this.respuestaValidarPrestador != null) {
        this.$http.post("msa-localapp/api/v1/rutas/crear-observacion-hospitalaria", formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(() => {
          if (esDevolucion) {
            this.dialogDevolverSolicitudHospi = false;
            this.showSuccess("La solicitud hospitalaria ha sido devuelta correctamente");
          } else {
            this.dialogObservacionHospi = false;
            this.showSuccess("Se ha creado la observación correctamente");
          }
          this.getListaSolicitudes();
          this.cerrarDialogObservacionHospi();
        }).catch((err) => {
          console.log(err);
          this.showError("Error inesperado al crear la observación");
          this.cerrarDialogObservacionHospi();
        });
      } else {
        this.showError("Debe agregar un prestador antes de ingresar el código de aceptación");
      }
    },

    cerrarDialogSolicitud(objeto) {
      this.dialogSolicitud = objeto.dialogSolicitud;
      this.tipoSolicitudRequerida = objeto.tipoSolicitudRequerida
    },
    cerrarDialogHospitalario(objeto) {
      this.getListaSolicitudes();
      this.dialogHospitalario = objeto.dialogHospitalario;
      this.tipoSolicitudRequerida = objeto.tipoSolicitudRequerida
    },
    cerrarDialogObservacion() {
      this.dialogObservacion = false;
      this.limpiarObservacionAmbulatorio()
      this.$refs.observerAnotation.reset();
    },
    limpiarObservacionAmbulatorio() {
      this.formNewObservation.fechaGestion = "";
      this.formNewObservation.fechaCita = "";
      this.formNewObservation.horaCita = "";
      this.formNewObservation.radicado = "";
      this.formNewObservation.prestador = {};
      this.formNewObservation.fechaCarga = "";
      this.formNewObservation.observacion = "";
    },
    limpiarObservacionHospitalario() {
      this.formNewObservationHospi.prestador = {};
      this.formNewObservationHospi.codigoAceptacion = "";
      this.formNewObservationHospi.ambulancia = "";
      this.formNewObservationHospi.PrestadorAmbulancia = "";
      this.formNewObservationHospi.observacion = "";
      this.formNewObservationHospi.anexo = [];
    },

    cerrarDialogObservacionHospi() {
      this.dialogObservacionHospi = false;
      this.limpiarObservacionHospitalario();
      this.$refs.observerAnotationHospi.reset();
    },

    informacionDetalle(item) {
      return new Promise((resolve) => {
        this.$http.get("msa-localapp/api/v1/rutas/solicitud/" + item.idSolicitud).then((result) => {
          console.log(result)
          switch (item.tipoSolicitudRuta) {
            case 'Ambulatorio':
              this.informacionContrarreferencia = result.data.anexo.filter(anexo => {
                return (anexo.tipoAdjunto === "Factura" || anexo.tipoAdjunto === "Soporte de atención");
              });

              result.data.anexo = result.data.anexo.filter(anexo => {
              return (anexo.tipoAdjunto === "Historia Clínica" || anexo.tipoAdjunto === "Orden medica" || anexo.tipoAdjunto === "Otro adjunto") });
                this.respuestaDetalle = result.data;
                console.log(this.respuestaDetalle)
              resolve(true)
            break;
            case 'Hospitalización-Remisión':
            case 'Hospitalización-Rescate':
            case 'Hospitalización-Domiciliario':
              this.informacionContrarreferencia = result.data.anexo.filter(anexo => {
                return (anexo.tipoAdjunto === "Factura" || anexo.tipoAdjunto === "Soporte de atención");
              });

              result.data.anexo = result.data.anexo.filter(anexo => {
              return (anexo.tipoAdjunto === "Anexo");})
                this.respuestaDetalle = result.data;
              resolve(true)   
            break;
          }
        })
      })
    },

    async listarDetalle(item) {
      this.getListaGrupoDiagnostico(item.grupoCIE);
      this.estadoSolicitud = item.estadoSeguimiento == 'Anulado' ? true : false;
      if (item.tipoSolicitudRuta == 'Ambulatorio') {
        await this.informacionDetalle(item)
        this.getTipoDocumentos();
        this.getListaPrestadores();
        this.getPrestadorPorUsuario();
        this.getListaMunicipios()
        this.getListaCodigosCups();
        this.getListaEspecialidaes();
        this.getObservacionesAmbulatorio(item);
        this.$refs.formularioAmbulatorio.$emit('llenarCamposDetalles', this.respuestaDetalle);
        this.activeForm = true;
        this.activeEdit = true;
        this.visiblePdf = false;
        this.activeGuardar = false;
        this.mensajePdf = "Lista pdf cargados"
        this.dialogSolicitud = true;
      }
      if (item.tipoSolicitudRuta !=  'Ambulatorio') {
        this.getTipoDocumentos();
        this.getObservacionesHospitalarias(item);
        this.getListaPrestadores();
        this.getPrestadorPorUsuario();
        this.getListaEspecialidaes();
        await this.informacionDetalle(item);
        this.activeForm = true;
        this.$refs.formularioHospitalario.$emit('llenarCamposDetalles', this.respuestaDetalle);
        this.dialogHospitalario = true;
      }
    },

    anularSolicitud(item){
      console.log(item.idSolicitud)
      this.dialogAnulacionRutas = true;
      this.idSolicitudAnulada = item.idSolicitud
    },

    cerrarDialogAnulacionRutas(){
      this.dialogAnulacionRutas = false;
    },

    getObservacionesAmbulatorio(item) {
      this.$http.get("msa-localapp/api/v1/rutas/observacion/ambulatoria/" + item.idSolicitud).then((result) => {
        this.listaObservaciones = result.data
      })
    },
    getObservacionesHospitalarias(item) {
      this.$http.get("msa-localapp/api/v1/rutas/observacion/hospitalaria/" + item.idSolicitud).then((result) => {
        this.listaObservacionesHospitalarias = result.data.map((item) => {
          const {fechaObservacion, ...element} = item
          return {
            fechaObservacion: moment(fechaObservacion).format('YYYY-MM-DD HH:mm'),
            ...element,
          }
        })
        console.log(this.listaObservacionesHospitalarias)
      }).catch(err => console.log(err))
    },

    visualizarDocumentoSubido(item) {
      this.pageNum = 1;
      this.numPaginas = 0;
      this.inhabilitarBotonesPdf = true;
      this.pdfUrl = URL.createObjectURL(item);
      this.dialogPdf = true;
    },

    cerrarDialogPdf(objeto) {
      this.dialogPdf = objeto.dialogPdf;
      this.pdfUrl = "";
      this.pageNum = 1;
      this.numPaginas = 0;
      this.inhabilitarBotonesPdf = false;
    },

    saveDateGestion(date) {
      this.$refs.menuFechaGestion.save(date)
    },
    saveDateCarga(date) {
      this.$refs.menuFechaCarga.save(date)
    },
    saveDateCita(date) {
      this.$refs.menuFechaCita.save(date)
    },
    saveDateFiltro(date) {
      this.$refs.menuFechaFiltro.save(date)
    },

    limpiarFechaFiltro() {
      this.fechaFiltro = '';
      this.$refs.menuFechaFiltro.save(this.fechaFiltro);
      this.menuFechaFiltro = false;
    },
    getPrestadorPorUsuario() {
      this.$http.get("msa-localapp/api/getPrestadorPorUsuario").then((result) => {
        console.log(result.data)
        this.listaIps2 = result.data;
      })
    },
  },
  watch: {
    menu(val) { val && setTimeout(() => (this.activePicker = 'YEAR')) },
    "formNuevaSolicitud.fechaSolicitud": function () {
    },

    "tipoSolicitudRequerida": function () {
      if (this.tipoSolicitudRequerida != null && this.ruta.idRuta != null) {
        this.dialogPresolicitud = false;
        this.abrirDialogSolicitud();
      }
    },
    "ruta": function () {
      if (this.tipoSolicitudRequerida != null && this.ruta.idRuta != null) {
        this.dialogPresolicitud = false;
        this.abrirDialogSolicitud();
      }
    },
    "filtroNumeroSolicitud": debounce(function () {
      this.getListaSolicitudes();
    }, 500),
    "filtroNumeroDocumento": debounce(function () {
      this.getListaSolicitudes();
    }, 500),

    "filtroTipoDocumento": function () { this.getListaSolicitudes()},
    "filtroIncluidoRuta": function () { this.getListaSolicitudes()},
    "filtroCohorte": function () { this.getListaSolicitudes()},
    "filtroAutoinmune": function () { this.getListaSolicitudes()},
    "estadoFiltro": function () { this.getListaSolicitudes()},
    "filtroCups": function () { this.getListaSolicitudes()},
    "filtroSolicitante": function () { this.getListaSolicitudes()},
    "filtroPrestador": function () { this.getListaSolicitudes()},
    "fechaFiltro": function () { this.getListaSolicitudes()},                                                   
    "itemsPorPagina": function () { this.getListaSolicitudes(); },
    "pagina": function () { this.getListaSolicitudes() },

  }
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
::v-deep .v-toolbar__content {
  justify-content: space-between !important;
}

::v-deep .tableResults div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .tableResults div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

th {
  color: black !important;
}

::v-deep .tableResults div {
  --sb-track-color: #d1cfcf;
  --sb-thumb-color: #8b8585;
  --sb-size: 8px;
}

::v-deep .tableResults div::-webkit-scrollbar {
  width: var(--sb-size)
}

::v-deep .v-messages {
 display: none;
}
::v-deep .v-text-field__details {
 display: none;
}

::v-deep .tableResults div::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 6px;
}

::v-deep .tableResults div::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 6px;

}

::v-deep .v-input__prepend-outer {
  display: none;
}

::v-deep .v-input__icon--prepend {
  display: none;
}

.color-red {
  color: red; /* Define el color rojo */
}

/* Estilos generales para fijar header encabezado columna 1 */
::v-deep .tableResults div table thead th:nth-child(1) {
  position: sticky !important;
  top: 0 !important;
  z-index: 100 !important;
  box-shadow: inset 0 -1px 0 #ddd !important;
  border-bottom: 1px solid #ddd !important;
}

/* Estilos generales para fijar header encabezado columna 2 */
::v-deep .tableResults div table thead th:nth-child(2) {
  position: sticky !important;
  top: 0 !important;
  z-index: 100 !important;
  box-shadow: inset 0 -1px 0 #ddd !important;
  border-bottom: 1px solid #ddd !important;
}

/* Estilos generales para fijar header encabezado columna 3 */
::v-deep .tableResults div table thead th:nth-child(3) {
  position: sticky !important;
  top: 0 !important;
  z-index: 100 !important;
  box-shadow: inset 0 -1px 0 #ddd !important;
  border-bottom: 1px solid #ddd !important;
}

/* Estilos generales para fijar header encabezado columna 1 */
::v-deep .tableResults div table thead th:nth-child(4) {
  position: sticky !important;
  top: 0 !important;
  z-index: 100 !important;
  box-shadow: inset 0 -1px 0 #ddd !important;
  border-bottom: 1px solid #ddd !important;
}

/* Estilos generales para fijar header encabezado columna 18 */
::v-deep .tableResults div table thead th:nth-child(18) {
  position: sticky !important;
  top: 0 !important;
  z-index: 100 !important;
  box-shadow: inset 0 -1px 0 #ddd !important;
  border-bottom: 1px solid #ddd !important;
}

/* Primera columna */
::v-deep .tableResults div table thead th:nth-child(1),
::v-deep .tableResults div table tbody tr td:nth-child(1) {
  position: sticky !important;
  left: 0;
  top: 0;
  background-color: #eee;
  z-index: 99;
}

/* Segunda columna */
::v-deep .tableResults div table thead th:nth-child(2),
::v-deep .tableResults div table tbody tr td:nth-child(2) {
  position: sticky !important;
  left: 92px; /* Ajusta según el ancho de la primera columna */
  top: 0;
  background-color: #eee;
  z-index: 98;
}

/* Tercera columna */
::v-deep .tableResults div table thead th:nth-child(3),
::v-deep .tableResults div table tbody tr td:nth-child(3) {
  position: sticky !important;
  left: 224px; /* Ajusta según el ancho de las dos primeras columnas */
  top: 0;
  background-color: #eee;
  z-index: 97;
}

/* Cuarta columna */
::v-deep .tableResults div table thead th:nth-child(4),
::v-deep .tableResults div table tbody tr td:nth-child(4) {
  position: sticky !important;
  left: 329px; /* Ajusta según el ancho de las tres primeras columnas */
  top: 0;
  background-color: #eee;
  z-index: 96;
}

/* Última columna (acciones) */
::v-deep .tableResults div table thead th:last-child,
::v-deep .tableResults div table tbody tr td:last-child {
  position: sticky !important;
  right: 0;
  top: 0;
  background-color: #eee;
  z-index: 95;
}

/* Resto de las columnas (para que queden por debajo) */
::v-deep .tableResults div table thead th:nth-child(n+5):nth-child(-n+17) {
  position: sticky;
  top: 0;
  z-index: 1; /* Asegura que el resto de los encabezados estén por debajo de los primeros 4 */
}

/* Estilo para asegurar que el autocomplete no se oculte */
::v-deep .v-autocomplete {
  z-index: 105 !important; /* Asegura que esté encima del header */
}

/* Estilo para las opciones desplegables del autocomplete */
::v-deep .v-menu__content {
  z-index: 105 !important; /* Debe ser mayor al z-index del header */
}

</style>
